// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// toastr
import toastr from 'toastr';

toastr.options = {
  'closeButton': true,
  'positionClass': 'toast-bottom-right',
  'timeOut': '3000'
};

global.toastr = toastr;

// IEアラート
import { alertModal } from './shared/alert_modal'
import SelectSearchable from './shared/select_searchable'

$(document).on('turbolinks:load', function() {
  CommonFunctions.showToastErrorWithScreens();
  SelectSearchable.init();
});

const CommonFunctions = {
  showIEError(path) {
    var ua = window.navigator.userAgent;
    var msie =/Edge\/|Trident\/|MSIE /.test(ua);
    // Return if not IE browser
    if (!msie) { return; }
    if(window.location.href.indexOf("/partner/fields") >= 0 || window.location.href.indexOf("/prime/fields") >= 0) {
      alertModal('Internet Explorerは非対応です。\n Chrome、Edge、Firefox、Safari\nのいずれかで利用してください。', path)
    }
  },
  showToastErrorWithScreens: function() {
    var currentScreen = window.location.pathname.split("/")[1];
    if (currentScreen == "prime" || currentScreen == "partner") {
      this.showIEError("/" + currentScreen + "/logout");
    }
  }
}

export default CommonFunctions;
